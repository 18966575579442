var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticStyle: { height: "100%" },
    },
    [
      _vm.view === _vm.FINANCE_VIEW.MAIN_FINANCE && !_vm.loading
        ? _c(
            "div",
            { staticClass: "div-main-list-page" },
            [
              _vm._m(0),
              _c(
                "el-row",
                { staticClass: "top-tab-botton-bar", attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 8, md: 10 } },
                    [
                      _c(
                        "el-tabs",
                        {
                          staticStyle: { width: "270px" },
                          on: {
                            "tab-click": function ($event) {
                              return _vm.getTableList(
                                _vm.pagination.pageSize,
                                0
                              )
                            },
                          },
                          model: {
                            value: _vm.tabActive,
                            callback: function ($$v) {
                              _vm.tabActive = $$v
                            },
                            expression: "tabActive",
                          },
                        },
                        _vm._l(_vm.FINANCE_TAB_LIST, function (item) {
                          return _c("el-tab-pane", {
                            key: item.name,
                            attrs: { label: item.label, name: item.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "flex-end",
                      staticStyle: { "white-space": "nowrap" },
                      attrs: { xs: 24, sm: 16, md: 14 },
                    },
                    [
                      _vm.isFinancier &&
                      _vm.tabActive === _vm.FINANCE_TAB_LIST[0].name
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                "padding-top": "5px",
                                "white-space": "nowrap",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.selectedInvoiceRequest.length == 0,
                                    round: "",
                                    type: "success",
                                  },
                                  on: { click: () => _vm.handleApprove(true) },
                                },
                                [_vm._v(" Approve ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isFinancier
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                "padding-top": "5px",
                                "white-space": "nowrap",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { round: "", type: "primary" },
                                  on: { click: _vm.onCreate },
                                },
                                [_vm._v(" Create new ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "div-main-list-page-table" },
                [
                  _c(
                    "el-table",
                    {
                      ref: "refListTable",
                      attrs: {
                        data: _vm.tableData,
                        height: "100%",
                        "row-key": "id",
                        border: true,
                        "header-cell-style": _vm.HEADER_CELL_STYLE,
                        "cell-style": _vm.CELL_STYLE,
                      },
                      on: { "selection-change": _vm.handleSelectionChange },
                    },
                    [
                      _vm.isFinancier &&
                      _vm.tabActive === _vm.FINANCE_TAB_LIST[0].name
                        ? _c("el-table-column", {
                            key: "checkbox",
                            attrs: { type: "selection", "min-width": "55" },
                          })
                        : _vm._e(),
                      _vm.tabActive === _vm.FINANCE_TAB_LIST[1].name
                        ? _c("el-table-column", {
                            attrs: { label: "Status", "min-width": "240px" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "status-tag",
                                          style: _vm.getStatusTagStyle(
                                            scope.row.isOverdue
                                              ? "OVERDUE"
                                              : scope.row.status
                                          ),
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row.isOverdue
                                                  ? `${scope.row.displayStatus}-Over Due`
                                                  : scope.row.displayStatus
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              270971009
                            ),
                          })
                        : _vm._e(),
                      _c("el-table-column", {
                        attrs: {
                          label: "Request Number",
                          "min-width": "150px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.systemGeneratedInvoiceNo
                                          ? scope.row.systemGeneratedInvoiceNo
                                          : "_"
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2370635616
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Requestor", "min-width": "100px" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.requesterName
                                          ? scope.row.requesterName
                                          : "_"
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2032672128
                        ),
                      }),
                      !_vm.isFinancier
                        ? _c("el-table-column", {
                            attrs: { label: "Financer", "min-width": "200px" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.status === _vm.FINANCE_STATUS[0]
                                        ? _c(
                                            "div",
                                            _vm._l(
                                              scope.row.pendingFinanciers,
                                              function (item) {
                                                return _c(
                                                  "p",
                                                  { key: item.id },
                                                  [_vm._v(_vm._s(item.name))]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _c("p", [
                                            _vm._v(
                                              _vm._s(scope.row.financier.name)
                                            ),
                                          ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2999335899
                            ),
                          })
                        : _vm._e(),
                      _c("el-table-column", {
                        attrs: { label: "Amount", "min-width": "120px" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("p", [_vm._v(_vm._s(scope.row.amount))]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3162712502
                        ),
                      }),
                      _vm.isFinancier
                        ? _c("el-table-column", {
                            attrs: {
                              label: "Platform Fee (%)",
                              "min-width": "150px",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.platformFeePercentage
                                        ? _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.platformFeePercentage
                                              )
                                            ),
                                          ])
                                        : _c("p", [_vm._v("-")]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              303178748
                            ),
                          })
                        : _vm._e(),
                      _vm.isFinancier
                        ? _c("el-table-column", {
                            attrs: {
                              label: "Platform Fee Amount",
                              "min-width": "150px",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.platformFee
                                        ? _c("p", [
                                            _vm._v(
                                              _vm._s(scope.row.platformFee)
                                            ),
                                          ])
                                        : _c("p", [_vm._v("-")]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3649073468
                            ),
                          })
                        : _vm._e(),
                      !_vm.isFinancier &&
                      _vm.tabActive !== _vm.FINANCE_TAB_LIST[0].name
                        ? _c("el-table-column", {
                            attrs: {
                              label: "Discount Rate (%)",
                              "min-width": "150px",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.discountRatePercentage
                                        ? _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.discountRatePercentage
                                              )
                                            ),
                                          ])
                                        : _c("p", [_vm._v("-")]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              70034588
                            ),
                          })
                        : _vm._e(),
                      !_vm.isFinancier &&
                      _vm.tabActive !== _vm.FINANCE_TAB_LIST[0].name
                        ? _c("el-table-column", {
                            attrs: {
                              label: "Receivable Amount",
                              "min-width": "150px",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row
                                        .amountReceivableAfterDiscountRate
                                        ? _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                scope.row
                                                  .amountReceivableAfterDiscountRate
                                              )
                                            ),
                                          ])
                                        : _c("p", [_vm._v("-")]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2806102204
                            ),
                          })
                        : _vm._e(),
                      _vm.tabActive !== _vm.FINANCE_TAB_LIST[0].name
                        ? _c("el-table-column", {
                            attrs: {
                              label: "Supporting Documents",
                              "min-width": "160px",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      [
                                        _vm.FINANCE_STATUS[2],
                                        _vm.FINANCE_STATUS[3],
                                        _vm.FINANCE_STATUS[4],
                                      ].includes(scope.row.status)
                                        ? _c(
                                            "p",
                                            {
                                              staticStyle: { color: "#1EAD24" },
                                            },
                                            [_vm._v("Uploaded")]
                                          )
                                        : _c(
                                            "p",
                                            {
                                              staticStyle: { color: "#FF2929" },
                                            },
                                            [_vm._v("Not Uploaded")]
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3011258556
                            ),
                          })
                        : _vm._e(),
                      _vm.tabActive !== _vm.FINANCE_TAB_LIST[0].name
                        ? _c("el-table-column", {
                            attrs: { label: "MT103", "min-width": "150px" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      [
                                        _vm.FINANCE_STATUS[3],
                                        _vm.FINANCE_STATUS[4],
                                      ].includes(scope.row.status)
                                        ? _c(
                                            "p",
                                            {
                                              staticStyle: { color: "#1EAD24" },
                                            },
                                            [_vm._v("Uploaded")]
                                          )
                                        : _c(
                                            "p",
                                            {
                                              staticStyle: { color: "#FF2929" },
                                            },
                                            [_vm._v("Not Uploaded")]
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1759036551
                            ),
                          })
                        : _vm._e(),
                      _c("el-table-column", {
                        attrs: { "min-width": "50px", fixed: "right" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("dropdown-action-menu", {
                                    attrs: {
                                      actionList: _vm.actionList,
                                      propsData: scope.row,
                                    },
                                    on: {
                                      handleDropdownMenuClick:
                                        _vm.handleDropdownActionsClick,
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          792930572
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "pagination-container" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          background: "",
                          "page-size": _vm.pagination.pageSize,
                          "page-sizes": [5, 10, 20, 50, 100],
                          "current-page": _vm.pagination.pageNumber,
                          layout: "total, sizes, prev, pager, next",
                          total: _vm.pagination.total,
                        },
                        on: {
                          "size-change": _vm.handlePageSizeChange,
                          "current-change": _vm.handlePageNumberChange,
                          "update:pageSize": function ($event) {
                            return _vm.$set(_vm.pagination, "pageSize", $event)
                          },
                          "update:page-size": function ($event) {
                            return _vm.$set(_vm.pagination, "pageSize", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      [_vm.FINANCE_VIEW.CREATE_FINANCE, _vm.FINANCE_VIEW.EDIT_FINANCE].includes(
        _vm.view
      ) && !_vm.loading
        ? _c(
            "div",
            { staticClass: "div-main-page", staticStyle: { height: "100%" } },
            [
              _c(
                "finance-form",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        isFinancier: _vm.isFinancier,
                        view: _vm.view,
                        formId: _vm.formId,
                        systemGeneratedInvoiceNo: _vm.systemGeneratedInvoiceNo,
                        requestNo: _vm.requestNo,
                      },
                      on: {
                        "update:view": function ($event) {
                          _vm.view = $event
                        },
                        backToMainListPage: _vm.refeshMainListPage,
                        handleApprove: _vm.handleApprove,
                        reqFinanciersOrSuppliersRegist:
                          _vm.reqFinanciersOrSuppliersRegist,
                      },
                    },
                    "finance-form",
                    _vm.$attrs,
                    false
                  ),
                  _vm.$listeners
                )
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "div-main-list-page-title" }, [
      _c("h1", [_vm._v("Financing")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }